import React, { useState, useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const RepushLead = ({ lead, updateRow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasCrmId, setHasCrmId] = useState(lead.crm_id !== null);
  const currentUser = decode();

  useEffect(() => {
    setHasCrmId(lead.crm_id !== null);
  }, [lead.crm_id]);

  const handleRepush = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/leads/${lead.id}/repush`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to repush lead");
      }

      const { success, data, message } = await response.json();

      if (!success) {
        throw new Error(message || "Failed to repush lead");
      }

      // Update the row with the new data which includes crm_id
      updateRow(data);

      // Update local state based on whether we got a crm_id
      setHasCrmId(data.crm_id !== null);

    } catch (error) {
      console.error("Error repushing lead:", error);
      // You could add a Snackbar or other error notification here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    !hasCrmId && (
      <Tooltip title="Repush to CRM">
        <IconButton onClick={handleRepush} disabled={isLoading}>
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
    )
  );
};

export default RepushLead;